import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import iziToast from 'izitoast';

const ProductDetail = () => {
  const { productId } = useParams();
  const { apipath, filepath } = useAuth();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch product details
  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(`${apipath}/product/details/${productId}`);
      setFormData(response.data.product);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Product Details:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [productId]);

  // Add to cart function
  const addToCart = async () => {
    if (!formData) {
      iziToast.error({
        title: 'Error',
        message: 'Product data is not available.',
        position: 'topCenter',
        timeout: 2000,
      });
      return;
    }

    const token = localStorage.getItem('token');

    try {
      await axios.post(
        `${apipath}/cart/add`,
        {
          productId: formData._id,
          productName: formData.productName,
          productPic: formData.productPic,
          price: formData.price,
          quantity: 1,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      iziToast.success({
        title: 'Success',
        message: 'Item added to cart successfully!',
        position: 'topCenter',
        timeout: 2000,
      });
    } catch (error) {
      console.error('Error adding to cart:', error);
      iziToast.error({
        title: 'Error',
        message: 'Failed to add item to cart.',
        position: 'topCenter',
        timeout: 2000,
      });
    }
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching product details</div>;
  }

  return (
    <>
      <Navbar />

      <div className="container-fluid main-banner">
        <div className="heading-main">
          <h1 className="text-[48px] text-white font-candal text-center">
            E-Catalogue
          </h1>
          <h4 className="text-[17px] font-exo text-white font-medium text-center">
            Home{" "}
            <i className="bi bi-chevron-double-right text-light-yellow"></i>
            E-catalogue
          </h4>
        </div>
      </div>

      <div className="container my-5 pb-2 border-b border-[#C0C0C0]">
        <div className="font-jost text-base font-medium">
          <span className="text-enquiry-button">Category </span>
          <i className="bi bi-chevron-right text-enquiry-button"></i>
          <span className="text-black"> Channel </span>
          <i className="bi bi-chevron-right text-enquiry-button"></i>
          <span className="text-black"> {formData?._id} </span>
        </div>
      </div>

      <div className="container">
        <div className="w-full lg:w-8/12 mx-auto">
          <div>
            <img src={formData.productPic !== '' && formData.productPic !== null ? filepath + formData.productPic : `${process.env.PUBLIC_URL}/imgs-alu/products.png`} alt={formData.productName} className="w-full h-[500px] mb-4" />
          </div>
          <table className="w-full p-2 mx-auto table-style">
            <thead>
              <tr className="bg-enquiry-button font-nunito text-base font-semibold text-center text-white">
                <th className="p-2 border border-black">Section Id</th>
                <th className="p-2 border border-black">A</th>
                <th className="p-2 border border-black">B</th>
                <th className="p-2 border border-black">T</th>
                <th className="p-2 border border-black">WT.KG/M</th>
                <th className="p-2 border border-black">Download</th>
                <th className="p-2 border border-black">Enquiry</th>
                <th className="p-2 border border-black">Die Available</th>
              </tr>
            </thead>
            <tbody>
              {formData && (
                <tr className="font-nunito text-base font-semibold text-center">
                  <td className="p-2 border border-black">{formData.sectionId}</td>
                  <td className="p-2 border border-black">{formData.a}</td>
                  <td className="p-2 border border-black">{formData.b}</td>
                  <td className="p-2 border border-black">{formData.t}</td>
                  <td className="p-2 border border-black">{formData.wtkgm}</td>
                  <td className="p-2 border border-black">
                    <Link className="no-underline text-black" to={formData.downloadLink}>
                      <i className="bi bi-download"></i>
                    </Link>
                  </td>
                  <td className="p-2 border border-black">
                    <Link className="no-underline text-black" to={formData.enquiryLink}>
                      <i className="bi bi-headset"></i>
                    </Link>
                  </td>
                  <td className="p-2 border border-black">{formData.dieAvailable ? "Yes" : "No"}</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="container my-5 pt-3 flex justify-between border-t border-[#C0C0C0]">
            <div className="font-poppins text-base font-medium text-black">
              <span>Price:₹ </span>
              <span className="text-enquiry-button">{formData.price}</span>
            </div>
            <div>
              <Link to="/specification">
                <button className="py-2 px-6 rounded-pill bg-enquiry-button font-poppins font-medium text-white text-base border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
                  Check Specifications
                </button>
              </Link>
              <button onClick={addToCart}
                className="py-2 px-6 rounded-pill bg-enquiry-button font-poppins font-medium text-white text-base border-1 border-enquiry-button hover:bg-transparent hover:!text-enquiry-button hover:ease-in-out duration-300">
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProductDetail;
